import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

//Importing Assets
import BANNER from '../../Assets/images/finance_BANNER.png'
import ABOUTUS from '../../Assets/images/aboutus_image.jpg'

import EXPERTISE from '../../Assets/images/expertiseIMG.jpg'
import COMMITMENT from '../../Assets/images/commitmentIMG.jpg'
import FOCUS from '../../Assets/images/focusIMG.jpg'
import COMPREHENSIVE from '../../Assets/images/comprehensiveIMG.jpg'
import PROACTIVE from '../../Assets/images/proactiveIMG.jpg'
import CUSTOMER from '../../Assets/images/customerIMG.jpg'

import BANNERMODEL from '../../Assets/images/bannerModel.png'

//Importing FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

//Importing Styles
import './homePageStyle.css'
import './servicesSectionStyle.css'
import './pricingSectionStyle.css'
import './chooseusSectionStyle.css'
import './newBannerStyle.css'

//Importing Components
import RequestCallbackForm from '../../Components/Contact/RequestCallbackForm'

//Importing GSAP for Animation
import gsap from 'gsap'; // <-- import GSAP
import { useGSAP } from '@gsap/react'; // <-- import the hook from our React package
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)

const Homepage = ({ states }) => {

    //Refs
    const bannerContainer = useRef(null)
    const about = useRef(null)
    const services = useRef(null)
    const pricing = useRef(null)
    const chooseUs = useRef(null)
    const faq = useRef(null)
    const visitUs = useRef(null)

    const pricingSlider = useRef(null)

    //Variables
    const [requestFormDisplay, setrequestFormDisplay] = useState({ status: false, data: '' })
    const bannerServicesData = [
        'Income Tax Filing',
        'GST Registration',
        'Trademark',
        'Brand Registration',
        'Digital Signature',
        'Food License',
        'Company Registration',
        'PAN Registration',
        'Trade License'
    ]

    //Animation
    useGSAP(
        (context, contextSafe) => {
            // gsap code here...
            const bannerAnimation = gsap.timeline()
            bannerAnimation.from(bannerContainer.current.querySelector('.modelContainer'), { x: '70%', opacity: 0, duration: 0.4, ease: "back.out(1.7)", }) // <-- automatically reverted
                .from(bannerContainer.current.querySelector('.contentContainer .content h5'), {x: '-50%', opacity: 0, duration: 0.4})
                .from([bannerContainer.current.querySelector('.contentContainer .content h2'), bannerContainer.current.querySelector('.contentContainer .content p')], {x: "-50%", opacity: 0, duration: 0.5, stagger: 0.5})
                .from(bannerContainer.current.querySelectorAll('.contentContainer .buttonsContainer a'), {scale: 0, opacity: 0, duration: 0.2, ease: "back.out(2)", stagger: 0.2})

            let aboutAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: about.current,
                    start: "top 60%",
                }
            });

            aboutAnimation.from(about.current.querySelector('.aboutus_content'), { x: -200, opacity: 0, duration: 0.5 })
                .from(about.current.querySelector('.image_container'), { x: 200, opacity: 0, duration: 0.5 })

            let servicesAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: services.current,
                    start: "top 50%",
                }
            });

            servicesAnimation.from([services.current.querySelector('.col1 h2'), services.current.querySelector('.col1 p')], { x: -200, opacity: 0, duration: 0.3 })
                .from(services.current.querySelectorAll('.col1 ul li'), { y: -50, opacity: 0, duration: 0.3, stagger: 0.2 })
                .from(services.current.querySelectorAll('.col2 .card_container'), { scale: 0, duration: 0.3, delay: -1, stagger: 0.2 })
                .from(services.current.querySelectorAll('.col1 .submit-button'), { scaleX: 0, duration: 0.3 })

            let pricingAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: pricing.current,
                    start: "top 50%",
                }
            })

            pricingAnimation.from(pricing.current.querySelector('.heading h4'), { letterSpacing: '30px', opacity: 0, duration: 0.3 })
                .from(pricing.current.querySelector('.heading p'), { y: -20, opacity: 0, duration: 0.3 })
                .from(pricing.current.querySelectorAll('.slider_container .card'), { scale: 0, duration: 0.5, delay: -0.3, stagger: 0.4 })
                .from(pricing.current.querySelector('.footing .submit-button'), { x: -200, opacity: 0, duration: 0.5 })

            let chooseusAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: chooseUs.current,
                    start: "top 50%",
                }
            })

            chooseusAnimation.from(chooseUs.current.querySelector('.heading h4'), { letterSpacing: '30px', opacity: 0, duration: 0.3 })
                .from(chooseUs.current.querySelector('.heading p'), { y: -20, opacity: 0, duration: 0.3 })
                .from(chooseUs.current.querySelectorAll('.container .card'), { x: -100, opacity: 0, duration: 0.5, stagger: 0.3 })

            let faqAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: faq.current,
                    start: "top 70%",
                }
            })

            faqAnimation.from(faq.current, { x: '-100%', backgroundColor: 'white', duration: 0.5 })
                .from(faq.current.querySelector('.heading h4'), { x: -100, opacity: 0, duration: 0.4 })
                .from(faq.current.querySelector('.heading p'), { x: 100, opacity: 0, duration: 0.3 })
                .from(faq.current.querySelectorAll('.question_container div'), { y: -100, opacity: 0, duration: 0.5, stagger: 0.15 })

            let visitusAnimation = gsap.timeline({
                scrollTrigger: {
                    trigger: visitUs.current,
                    start: "top 60%",
                }
            })

            visitusAnimation.from(visitUs.current.querySelector('.visitus_heading'), { letterSpacing: '30px', opacity: 0, duration: 0.3 })
                .from(visitUs.current.querySelectorAll('.content_container .content div'), { x: -100, opacity: 0, duration: 0.5, stagger: 0.2 })
        },
        { scope: bannerContainer, about, services, pricing, chooseUs, faq, visitUs, }
    ); // <-- scope for selector text (optional)


    //Functions
    const handleRequestForm = (serviceType = '') => {
        if (requestFormDisplay.status) {
            setrequestFormDisplay({ status: false, data: '' })
            //UnLock Scroll
            document.body.style.overflow = '';
        } else {
            setrequestFormDisplay({ status: true, data: serviceType })
            //Lock Scroll
            document.body.style.overflow = 'hidden';
        }
    }

    const handleNextPricingScroll = () => {
        const Cards = pricingSlider.current.querySelectorAll('.card')
        const singleCardWidth = pricingSlider.current.querySelector('.card').offsetWidth
        let containerWidth = pricingSlider.current.offsetWidth
        let sum = 0
        //total width of Cards
        Cards.forEach((elem) => {
            sum = sum + elem.offsetWidth
        })
        sum = sum + 130 //130 for gap and Padding

        if (sum > containerWidth) {
            gsap.to(Cards, { x: `-${(sum - containerWidth)}px`, duration: 0.3 })
        }
    }

    const handlePrevPricingScroll = () => {
        const Cards = pricingSlider.current.querySelectorAll('.card')
        gsap.to(Cards, { x: `+${0}px`, duration: 0.3 })
    }

    return (
        <>
            {
                requestFormDisplay.status && (<div id='requestFormContainer'>
                    <RequestCallbackForm state={requestFormDisplay} func={handleRequestForm} serviceType={requestFormDisplay.data}></RequestCallbackForm>
                </div>)
            }
            {/* Banner Area -- New Banner*/}
            <div ref={bannerContainer} className='newBannerContainer'>
                <div className='contentContainer'>
                    <div className='content'>
                        <h5>Welcome to Finera</h5>
                        <h2>You've got a business,<br></br>we have got brilliant<br></br>minds.</h2>
                        <p>Our performance is your success. Our passion is innovation. Our expertise is unmatched. We get you more.</p>
                    </div>
                    <div className='buttonsContainer'>
                        {
                            bannerServicesData.map((item, idx) => (
                                <Link to={'/services&pricing'} state={item === 'Income Tax Filing' ? { category: 'IT Filing' } : { searchItem: item }} key={idx} className='button'>
                                    <h4>{item}</h4>
                                </Link>
                            ))
                        }

                    </div>
                </div>
                <div className='modelContainer'>
                    <svg xmlSpace="preserve" width="56.3586mm" height="66.6248mm" version="1.1" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }} viewBox="0 0 12040 14233" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n                                \n                                .fil0 {fill:#07D589}\n   \n                            " }} />
                            <mask id="id0">
                                <linearGradient id="id1" gradientUnits="userSpaceOnUse" x1={7459} y1={-8106} x2={7459} y2={13789}>
                                    <stop offset={0} style={{ stopOpacity: 1, stopColor: 'white' }} />
                                    <stop offset="0.239216" style={{ stopOpacity: '39.651', stopColor: 'white' }} />
                                    <stop offset={1} style={{ stopOpacity: 0, stopColor: 'white' }} />
                                </linearGradient>
                                <rect style={{ fill: 'url(#id1)' }} x={-21} y={-21} width={12083} height={14276} />
                            </mask>
                            <mask id="id2">
                                <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1={7070} y1={-10233} x2={7070} y2={13425}>
                                    <stop offset={0} style={{ stopOpacity: 1, stopColor: 'white' }} />
                                    <stop offset="0.0117647" style={{ stopOpacity: '39.651', stopColor: 'white' }} />
                                    <stop offset={1} style={{ stopOpacity: 0, stopColor: 'white' }} />
                                </linearGradient>
                                <rect style={{ fill: 'url(#id3)' }} x={1628} y={1628} width={8784} height={12605} />
                            </mask>
                        </defs>
                        <g id="PowerClip_x0020_Contents">
                            <metadata id="CorelCorpID_0Corel-Layer" />
                            <g id="_2000163518272">
                                <path className="fil0" style={{ mask: 'url(#id0)' }} d="M6020 0l0 0c3311,0 6020,2709 6020,6020l0 8213 -12040 0 0 -8213c0,-3311 2709,-6020 6020,-6020z" />
                                <path className="fil0" style={{ mask: 'url(#id2)' }} d="M10412 14233l0 -8213c0,-2412 -1980,-4392 -4392,-4392 -2412,0 -4392,1980 -4392,4392l0 8213 8784 0z" />
                            </g>
                        </g>
                    </svg>

                    <div className='imageContainer'>
                        <img src={BANNERMODEL}></img>
                    </div>
                </div>
            </div>

            {/* About Us Section */}
            <div ref={about} id='about' className="aboutus_section">
                <div className='aboutus_content'>
                    <h1>About <span>Us</span></h1>
                    <p>Welcome to FinEra - Your Trusted Partner for Business Legal Solutions! <br /> <br />

                        At FinEra, we are dedicated to providing comprehensive and reliable legal solutions tailored to meet the diverse needs of businesses. Founded with the mission to simplify the complexities of legal compliance and regulatory requirements, we aim to be your go-to resource for all things legal and financial.
                        <br />
                        FinEra was founded with a singular vision: to empower businesses by providing them with comprehensive, reliable, and efficient legal and financial solutions. Our team is comprised of seasoned professionals with extensive experience in tax consultancy, legal compliance, and business advisory services. We pride ourselves on our in-depth knowledge and commitment to excellence, which allows us to deliver top-notch services to our clients.
                    </p>
                </div>
                <div className='image_container'>
                    <img src={ABOUTUS} alt />
                    <div className='shape'></div>
                </div>
            </div>

            {/* Services Section */}
            <div ref={services} id='services' className='services_section'>
                <div className='col1'>
                    <h2>The Services<br />
                        <span>We Offer For You</span> </h2>
                    <p> At FinEra, we offer a wide array of services designed to meet the diverse needs of businesses across various industries. <br /> <br /> <span>Our Key Services include...</span> </p>
                    <ul>
                        <li>Tax Consultancy</li>
                        <li>GST Registration</li>
                        <li>ITR Filing</li>
                        <li>Trade License</li>
                        <li>Food License</li>
                        <li>... and many more.</li>
                    </ul>

                    <Link to="/services&pricing" className="submit-button">ALL SERVICES<i className="fa fa-angle-right" aria-hidden="true" /></Link>
                </div>
                <div className='col2'>
                    <svg id="bg-services" viewBox="0 0 1000 800">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                <stop offset="0%" stop-color="#11a770" stop-opacity="0.5"></stop>
                                <stop offset="100%" stop-color="#25ffaf" stop-opacity="1"></stop>
                            </linearGradient>
                        </defs>
                        <path fill-rule="evenodd" opacity="0.102" fill="url(#PSgrad_02)" d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"></path>
                    </svg>

                    <div className="service_col1">
                        <div className="card_container">
                            <div className="image_container"><img className="" src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" /></div>
                            <div className="content_container">
                                <h3 >Tax Consultancy</h3>
                                <p >Navigating tax laws can be daunting. Our expert consultants provide personalized tax advice, helping you to optimize your tax liabilities and ensure compliance with all applicable regulations.</p>
                            </div>
                        </div>
                        <div className="card_container">
                            <div className="image_container"><img className="" src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" /></div>
                            <div className="content_container">
                                <h3 >GST Registration</h3>
                                <p >GST compliance is crucial for businesses in India. We simplify the registration process, ensuring that your business is GST-compliant without any hassle.</p>
                            </div>
                        </div>
                    </div>

                    <div className="service_col2">
                        <div className="card_container">
                            <div className="image_container"><img className="" src="https://images.unsplash.com/photo-1562240020-ce31ccb0fa7d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" /></div>
                            <div className="content_container">
                                <h3 >ITR Filing</h3>
                                <p >Accurate and timely Income Tax Return (ITR) filing is essential for maintaining compliance and avoiding penalties. Our professionals handle your ITR filings, ensuring accuracy and timeliness.</p>
                            </div>
                        </div>
                        <div className="card_container">
                            <div className="image_container"><img className="" src="https://images.unsplash.com/photo-1487611459768-bd414656ea10?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" /></div>
                            <div className="content_container">
                                <h3 >.....and Many More</h3>
                                <p >Beyond the core services, we offer a plethora of other legal solutions including business incorporation, intellectual property rights protection, and more. Whatever your legal needs, FinEra has the expertise to assist you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pricing Section */}
            <div ref={pricing} className='pricing_section'>
                <div className='heading'>
                    <h4>Plans & <span>Pricings</span></h4>
                    <p>Check the best Tax Service for you or your business.</p>
                </div>
                <div className='nav_buttons'>
                    <div onClick={handlePrevPricingScroll}><FontAwesomeIcon icon={faArrowRight} flip="horizontal" size="sm" /></div>
                    <div onClick={handleNextPricingScroll}><FontAwesomeIcon icon={faArrowRight} size="sm" /></div>
                </div>
                <div ref={pricingSlider} className='slider_container'>
                    <div className='card'>
                        <div className='image_container' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cmVnaXN0cmF0aW9ufGVufDB8fDB8fHww')` }}>
                            <h4>Registrations</h4>
                        </div>
                        <p>TAN | GST | MSME | ...and many more</p>
                        <h2>Starting from<br /><span>Rs. 659/-</span></h2>
                        <div className='buttons'>
                            <Link to='/services&pricing' state={{ category: 'Registrations' }}>Checkout Plans</Link>
                            <a onClick={(e) => { e.preventDefault(); handleRequestForm('Registrations') }}>Request A Callback<i className="fa fa-angle-right" aria-hidden="true" /></a>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='image_container' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1562240020-ce31ccb0fa7d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>
                            <h4>Licenses</h4>
                        </div>
                        <p>Trade License | Food License | Fire License | ...and many more</p>
                        <h2>Starting from<br /><span>Rs. 999/-</span></h2>
                        <div className='buttons'>
                            <Link to='/services&pricing' state={{ category: 'Registrations' }}>Checkout Plans</Link>
                            <a onClick={(e) => { e.preventDefault(); handleRequestForm('Registrations') }}>Request A Callback<i className="fa fa-angle-right" aria-hidden="true" /></a>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='image_container' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>
                            <h4>TDS Preparation</h4>
                        </div>
                        <p>Sale of Property | Salary | NRI | Crypto Assets | ...and many more</p>
                        <h2>Starting from<br /><span>Rs. 1499/-</span></h2>
                        <div className='buttons'>
                            <Link to='/services&pricing' state={{ category: 'TDS Preparation' }}>Checkout Plans</Link>
                            <a onClick={(e) => { e.preventDefault(); handleRequestForm('TDS Preparation') }}>Request A Callback<i className="fa fa-angle-right" aria-hidden="true" /></a>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='image_container' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>
                            <h4>GST Filing</h4>
                        </div>
                        <p>GSTR 1 | GSTR 3B | GSTR 9 | GSTR 9C | ...and many more</p>
                        <h2>Starting from<br /><span>Rs. 999/-</span></h2>
                        <div className='buttons'>
                            <Link to='/services&pricing' state={{ category: 'GST Filing' }}>Checkout Plans</Link>
                            <a onClick={(e) => { e.preventDefault(); handleRequestForm('GST Filing') }}>Request A Callback<i className="fa fa-angle-right" aria-hidden="true" /></a>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='image_container' style={{ backgroundImage: `url('https://images.unsplash.com/photo-1460058418905-d61a1b4a55fe?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>
                            <h4>ITR Filing</h4>
                        </div>
                        <p>Single Salary | Multiple Salary | Small Business | Partnership Firm | ...and many more</p>
                        <h2>Starting from<br /><span>Rs. 999/-</span></h2>
                        <div className='buttons'>
                            <Link to='/services&pricing' state={{ category: 'IT Filing' }}>Checkout Plans</Link>
                            <a onClick={(e) => { e.preventDefault(); handleRequestForm('ITR Filing') }}>Request A Callback<i className="fa fa-angle-right" aria-hidden="true" /></a>
                        </div>
                    </div>
                    {/* <div className='card'>
                        <div className='image_container'>
                            <h4>ITR Filing</h4>
                        </div>
                        <p>akjdfkljaslkj aldsjfl asldkfj lakdjsfl ajdkf jakdfakl</p>
                        <h2>Starting from<br /><span>Rs. 0/-</span></h2>
                        <div className='buttons'>
                            <a>Checkout Plans</a>
                            <a>Request A Callback<i className="fa fa-angle-right" aria-hidden="true" /></a>
                        </div>
                    </div> */}
                </div>
                <div className='footing'>
                    <Link to="/services&pricing" className="submit-button">SEE ALL PRICINGS<i className="fa fa-angle-right" aria-hidden="true" /></Link>
                </div>
            </div>

            {/* Why Choose Us Section */}
            <div ref={chooseUs} className='chooseus_section'>
                <div className='heading'>
                    <h4>Why <span>Choose</span> Us?</h4>
                    <p>Our mission is to empower businesses by simplifying legal and financial complexities, enabling them to achieve compliance and focus on growth.</p>
                </div>
                <div className='container'>
                    <div className='card'>
                        <div className='icon_container'>
                            <img src={EXPERTISE}></img>
                        </div>
                        <h4>Expertise</h4>
                        <p>Our team of professionals brings a wealth of knowledge and experience in various domains of business law and finance.</p>
                    </div>
                    <div className='card'>
                        <div className='icon_container'>
                            <img src={COMMITMENT}></img>
                        </div>
                        <h4>Commitment</h4>
                        <p>We are dedicated to providing the highest level of service, ensuring that your business is always in compliance and positioned for success.</p>
                    </div>
                    <div className='card'>
                        <div className='icon_container'>
                            <img src={FOCUS}></img>
                        </div>
                        <h4>Client Focus</h4>
                        <p>Your success is our success. We work closely with you to understand your needs and deliver solutions that align with your business objectives.</p>
                    </div>
                    <div className='card'>
                        <div className='icon_container'>
                            <img src={COMPREHENSIVE}></img>
                        </div>
                        <h4>comprehensive solutions</h4>
                        <p>We offer a full suite of services, covering all your business legal needs from setup to ongoing compliance.</p>
                    </div>
                    <div className='card'>
                        <div className='icon_container'>
                            <img src={PROACTIVE}></img>
                        </div>
                        <h4>proactive approach</h4>
                        <p>We anticipate and address potential issues before they become problems, keeping you ahead of regulatory changes.</p>
                    </div>
                    <div className='card'>
                        <div className='icon_container'>
                            <img src={CUSTOMER}></img>
                        </div>
                        <h4>Customer centricity</h4>
                        <p>We prioritize your satisfaction with personalized attention and responsive support tailored to your business goals.</p>
                    </div>
                </div>
            </div>

            {/* FAQ's Section */}
            <div ref={faq} id='faq' className='faq_section'>
                <div className='heading'>
                    <h4>FAQ!  <span>Need Help?</span></h4>
                    <p>We've got you covered</p>
                </div>

                <div className='question_container'>
                    <div>
                        <input type='checkbox' id='first'></input>
                        <label htmlFor='first'><span>1</span> What is FinEra?</label>
                        <div className='answer_content'>
                            <p>
                                <strong>FinEra</strong> is the brand of Raza And Associates, a dynamic startup dedicated to providing comprehensive legal and financial solutions for businesses. Our services encompass a wide range of essential business needs, including GST registration and filing, Income Tax Return (ITR) filing, TDS compliance, trade licenses, food licenses, and various other legal solutions. At FinEra, we strive to ensure that businesses navigate complex regulatory requirements with ease, allowing them to focus on their core operations. Our expert team is committed to delivering personalized and reliable support to help your business achieve compliance and growth.
                            </p>
                        </div>
                    </div>
                    <div>
                        <input type='checkbox' id='second'></input>
                        <label htmlFor='second'><span>2</span> What services does FinEra offer?</label>
                        <div className='answer_content'>
                            <p>
                                <strong>FinEra</strong> offers a comprehensive range of legal solutions tailored for businesses. Our services include: <br /><br />

                                1. GST Registration and Filing: Assisting businesses in registering for GST and managing their regular GST filings.<br />
                                2. Income Tax Return {"(ITR)"} Filing: Providing expert support for accurate and timely ITR filing.<br />
                                3. TDS Compliance: Ensuring businesses meet their TDS obligations with thorough preparation and filing.<br />
                                4. Licensing Services: Facilitating the acquisition of essential business licenses, such as Trade Licenses and Food Licenses.<br />
                                5. Many More: Offering a variety of additional legal and financial services to meet the unique needs of your business.
                            </p>
                        </div>
                    </div>
                    <div>
                        <input type='checkbox' id='third'></input>
                        <label htmlFor='third'><span>3</span> What is the application process of any service with FinEra?</label>
                        <div className='answer_content'>
                            <p>
                                When a customer opts for a service with FinEra, they can begin the application process by clicking on the <strong>"Request a Callback"</strong> option from the services menu. The customer will then fill out a form with their <strong>phone number, email ID, name, and any additional message</strong>. Once the form is submitted, <strong><em>our dedicated team will contact the customer within 24 hours via email or phone to discuss the service further and initiate the process.</em></strong><br /><br />

                                At <strong>FinEra</strong>, we pride ourselves on our commitment to customer satisfaction and transparency. Our experienced professionals ensure that each step of the process is handled with the utmost care and efficiency. We understand the importance of trust in choosing a legal and financial partner, and we strive to build lasting relationships with our clients by delivering reliable and high-quality services.<br /><br />

                                Alternatively, customers are welcome to visit our office directly to speak with our team and start the application process in person. We are always ready to provide personalized support and address any questions or concerns.
                            </p>
                        </div>
                    </div>
                    <div>
                        <input type='checkbox' id='forth'></input>
                        <label htmlFor='forth'><span>4</span> How long does it take to complete my application?</label>
                        <div className='answer_content'>
                            <p>
                                The duration of the application process at FinEra varies based on the specifics of each case and individual circumstances. Our team of experts will conduct a thorough assessment of your situation to provide you with an accurate estimate of the time required. We understand that time is of the essence, and our team is committed to working tirelessly to address your needs efficiently. After evaluating your case, we will communicate all necessary details and timelines to ensure you have a clear understanding of the process ahead. We are dedicated to providing timely and effective solutions while prioritizing the unique needs of your application.
                            </p>
                        </div>
                    </div>
                    <div>
                        <input type='checkbox' id='fifth'></input>
                        <label htmlFor='fifth'><span>5</span> Why should I choose FinEra for my business’s legal solutions?</label>
                        <div className='answer_content'>
                            <p>
                                Choosing FinEra for your business's legal solutions means partnering with a dedicated team of professionals who prioritize your success. Our comprehensive range of services is designed to meet the diverse needs of businesses at every stage. With our expertise in tax consultancy, compliance, and licensing, we provide tailored solutions that ensure your business operates smoothly and efficiently. Our commitment to exceptional customer service and transparency sets us apart, as we strive to build lasting relationships based on trust and reliability. By choosing FinEra, you can focus on growing your business while we handle the complexities of legal matters.
                            </p>
                        </div>
                    </div>
                    <div>
                        <input type='checkbox' id='sixth'></input>
                        <label htmlFor='sixth'><span>6</span> How can I contact FinEra for support or queries?</label>
                        <div className='answer_content'>
                            <p>
                                You can easily contact FinEra for support or any queries by visiting our <a onClick={(e) => { e.preventDefault(); states('#contact') }} className='' href=''><strong>"Contact Us"</strong></a> section on the website. Here, you can submit your inquiries related to our services by providing your basic details, such as your email address and name. Our dedicated team will respond to you within 24 hours, ready to address all your questions and provide the assistance you need. We are here to help and ensure you have a seamless experience with our services.
                            </p>
                        </div>
                    </div>
                    <div>
                        <input type='checkbox' id='seventh'></input>
                        <label htmlFor='seventh'><span>7</span> What makes FinEra different from other tax consultancy firms?</label>
                        <div className='answer_content'>
                            <p>
                                FinEra stands out from other tax consultancy firms due to our unwavering commitment to personalized service and customer satisfaction. Our team of experienced professionals takes the time to understand each client's unique needs and challenges, allowing us to deliver tailored solutions that truly address their requirements.<br /><br />

                                Additionally, we prioritize transparency and communication, ensuring that our clients are informed and involved throughout the process. Our comprehensive range of services—from GST registration to ITR filing—coupled with our proactive approach to compliance, means we not only meet regulatory requirements but also help our clients achieve long-term financial success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Visit Us Section */}
            <div ref={visitUs} className='visitus_section'>
                <h4 className='visitus_heading'>Visit <span>Us</span></h4>
                <div className='content_container'>
                    <div className='content'>
                        <div>
                            <h6>Office <span>Location</span></h6>
                            <p>Ground Floor, Ashiyana Apartment, 59C, Gulam Jilani Khan Rd, Block C, Topsia, Kolkata, West Bengal 700039</p>
                        </div>

                        <div>
                            <h6>Opening <span>Hours</span></h6>
                            <p>Mon-Sat: 10:00 AM to 9:00 PM <br /> Sun: Closed <br /> *On Holiday Occassions hours may vary*</p>
                        </div>

                        <div className='contact_gmail'>
                            <p>Contact: <span style={{ fontSize: 20 }}>contact@finera.net.in</span></p>
                        </div>
                    </div>
                    <div className='map'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.1440898257797!2d88.38746681114368!3d22.53627453421475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0276b9b439a35f%3A0x4ee3c13e105a87de!2sRaza%20%26%20Associates!5e0!3m2!1sen!2sin!4v1720460853814!5m2!1sen!2sin" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Homepage