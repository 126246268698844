//DATA
const servicesDATA = [
    {
        category: 'Registrations',
        content: [
            {
                title: 'PAN Registration',
                amount: 149,
                discount: 15
            },
            {
                title: 'TAN Registration',
                amount: 659,
                discount: 20
            },
            {
                title: 'GST Registration',
                amount: 1800,
                discount: 10
            },
            {
                title: 'MSME Registration',
                amount: 629,
                discount: 15
            },
            {
                title: 'Private Ltd Registration',
                amount: 7798,
                discount: 18
            },
            {
                title: 'LLP Company Registration',
                amount: 7798,
                discount: 20
            },
            {
                title: 'Brand Registration',
                amount: 7798,
                discount: 15
            },
            {
                title: 'Trademark Registration',
                amount: 3499,
                discount: 10
            },
            {
                title: 'Digital Signature',
                amount: 1599,
                discount: 10
            },
        ]
    },
    {
        category: 'Licenses',
        content: [
            {
                title: 'Trade License',
                amount: 999,
                discount: 20
            },
            {
                title: 'Import & Export License',
                amount: 1199,
                discount: 15
            },
            {
                title: 'Fire License',
                amount: 1199,
                discount: 18
            },
            {
                title: 'Food License',
                amount: 1499,
                discount: 10
            },
        ]
    },
    {
        category: 'TDS Preparation',
        content: [
            {
                title: 'Sale of Property',
                amount: 1299,
                discount: 15
            },
            {
                title: 'Salary',
                amount: 1299,
                discount: 17
            },
            {
                title: 'Others',
                amount: 1299,
                discount: 10
            },
            {
                title: 'NRI Payments',
                amount: 1399,
                discount: 10
            },
            {
                title: 'TCS Payments',
                amount: 1299,
                discount: 13
            },
            {
                title: 'TDS on Rent',
                amount: 1299,
                discount: 15
            },
            {
                title: 'TDS on Crypto Assets',
                amount: 1299,
                discount: 5
            },
            {
                title: 'Other Payments',
                amount: 1499,
                discount: 15
            },
        ]
    },
    {
        category: 'GST Filing',
        content: [
            {
                title: 'Monthly GSTR 3B and GSTR 1 Filing',
                amount: 899,
                basis: 'monthly',
                discount: 10
            },
            {
                title: 'Quaterly GSTR 3B and GSTR 1 Filing',
                amount: 2299,
                basis: 'quarterly',
                discount: 15
            },
            {
                title: 'Annual GSTR Filing',
                amount: 7499,
                basis: 'yearly',
                discount: 20
            },
            {
                title: 'GSTR-9 Filing',
                amount: 2299,
                discount: 18
            },
            {
                title: 'GSTR-9C Filing with Tax Audit',
                amount: 7499,
                discount: 5
            }
        ]
    },
    {
        category: 'IT Filing',
        content: [
            {
                title: 'Single Salary',
                amount: 899,
                discount: 20
            },
            {
                title: 'Multiple Salaries',
                amount: 1499,
                discount: 18
            },
            {
                title: 'Complex / Capital Gains',
                amount: 3499,
                discount: 15
            },
            {
                title: 'Small Business',
                amount: 999,
                discount: 10
            },
            {
                title: 'Business Filing',
                amount: 3999,
                discount: 16
            },
            {
                title: 'Partnership Firm Filing',
                amount: 4499,
                discount: 18
            },
            {
                title: 'NRI IT Filing',
                amount: 2299,
                discount: 5
            },
            {
                title: 'Resident w Foreign Income',
                amount: 4999,
                discount: 16
            },
            {
                title: 'Futures & Options',
                amount: 3999,
                discount: 11
            },
            {
                title: 'Futures & Options w Tax Audit',
                amount: 14999,
                discount: 13
            },
            {
                title: 'Cryptos & Bitcoins',
                amount: 4999,
                discount: 7
            },
            {
                title: 'Notice Handling',
                amount: 999,
                discount: 10
            },
            {
                title: 'Tax Consultation',
                amount: 699,
                discount: 15
            },
            {
                title: 'LLP IT + ROC Filing',
                amount: 12999,
                discount: 12
            },
            {
                title: 'Pvt Ltd IT + ROC + Audit Filing',
                amount: 14999,
                discount: 8
            },
            {
                title: 'Form 15CA / 15CB Filing',
                amount: 4000,
                discount: 8
            },
            {
                title: 'Updated Return u/s 139(8A)',
                amount: 1799,
                discount: 10
            },
            {
                title: 'Defective Notice u/s 139(9)',
                amount: 799,
                discount: 5
            },
            {
                title: 'Form 13 (NIL TDS) Filing',
                amount: 4799,
                discount: 12
            },
        ]
    },
]

export { servicesDATA }