import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './services&PricingStyle.css'

//Importing Components
import RequestCallbackForm from '../../Components/Contact/RequestCallbackForm'

//Importing Assets

//Importing DATA
import { servicesDATA } from './DATA'

//Importing GSAP for Animation
import gsap from 'gsap'; // <-- import GSAP
import { useGSAP } from '@gsap/react'; // <-- import the hook from our React package

gsap.registerPlugin(useGSAP)

function Services_Pricing() {
    //Route
    const route = useLocation()

    //Data From Route
    const state = route ? route.state : null
    console.log(state)

    //Refs
    const servicesContainer = useRef(null)

    //Variables
    const [fixed, setfixed] = useState(false)
    const [active, setactive] = useState(state != null ? state.category : 'Registrations')
    let container = useRef(null);
    let [height, setHeight] = useState(null);

    const [requestFormDisplay, setrequestFormDisplay] = useState({ status: false, data: '' })
    const [searchBarDisplay, setsearchBarDisplay] = useState(false)

    const [searchedDATA, setsearchedDATA] = useState([])
    const [searchValue, setsearchValue] = useState(route ? route.state ? route.state.searchItem : "" : "")

    //Animation

    //Functions
    const handleCategoryPress = (_id) => {
        setactive(_id)
        setsearchValue('')
        setsearchedDATA([])
    }

    const searchInput = (value) => {
        setsearchValue(value)

        //Searching Logic
        let input = value
        var queryData = []
        servicesDATA.forEach((elems, idx) => {
            var temp = elems.content.filter(
                (item) => {
                    const pattern = new RegExp(input, "gi")
                    if (pattern.exec(item.title) != null) {
                        return true
                    } else {
                        return false
                    }
                }
            )

            if (temp.length != 0) {
                temp.forEach((obj) => {
                    queryData.push(obj)
                })
            }
        })

        console.log(queryData)
        setsearchedDATA(queryData)
        if (value != "") {
            setactive('')
        } else {
            setactive('Registrations')
            setsearchedDATA([])
        }
    }

    const handleFixedSidebar = () => {
        if (window.scrollY >= 350 && window.scrollY <= (height - 502)) {
            setfixed(true)
        } else {
            setfixed(false)
        }

        //Displaying Search Bar in Sidebar
        if (window.scrollY >= 243) {
            setsearchBarDisplay(true)
        } else {
            setsearchBarDisplay(false)
        }
    }
    window.addEventListener('scroll', handleFixedSidebar)

    const handleRequestForm = (serviceType = '') => {
        if (requestFormDisplay.status) {
            setrequestFormDisplay({ status: false, data: '' })
            //UnLock Scroll
            document.body.style.overflow = '';
        } else {
            setrequestFormDisplay({ status: true, data: serviceType })
            //Lock Scroll
            document.body.style.overflow = 'hidden';
        }
    }

    const calculateGrossAmount = (amount, percentage) => {
        let discountedPrice = 100 - percentage

        return Math.ceil((amount / discountedPrice) * 100)
    }

    useEffect(() => { window.scrollTo(0, 0); if(route.state && route.state.searchItem){ searchInput(route.state.searchItem)} }, []);

    useEffect(() => {
        setHeight(container.current.offsetHeight);
        handleFixedSidebar();
    }, [active])

    return (
        <>
            {
                requestFormDisplay.status && (<div id='requestFormContainer'>
                    <RequestCallbackForm state={requestFormDisplay} func={handleRequestForm} serviceType={requestFormDisplay.data}></RequestCallbackForm>
                </div>)
            }
            {/* Top Banner */}
            <div className='bannerSection'>
                <div className='container'>
                    <h4>Services We Offer.....</h4>
                    <div className='searchBar'>
                        <i className="fa fa-magnifying-glass" aria-hidden="true" />
                        <input type='search' placeholder='Search for service and hire an Expert' value={searchValue} onChange={(e) => searchInput(e.target.value)}></input>
                    </div>
                </div>
            </div>

            <div className='main_container' ref={container}>
                {/* Side Bar */}
                <div className={fixed ? 'sidebar_container fixed' : 'sidebar_container'}>
                    <h4><i class="fa fa-list"></i> Categories</h4>
                    <ul>
                        {
                            servicesDATA.map((obj, i) => (
                                <li key={i} onClick={() => handleCategoryPress(obj.category)} className={active == obj.category ? 'active' : ''}>{obj.category}</li>
                            ))
                        }
                    </ul>

                    <div className='searchBar' style={searchBarDisplay ? { display: 'flex' } : { display: 'none' }}>
                        <i className="fa fa-magnifying-glass" aria-hidden="true" />
                        <input type='search' placeholder='Search for service and hire an Expert' value={searchValue} onChange={(e) => searchInput(e.target.value)}></input>
                    </div>
                </div>

                {/* Top Bar for Mobile Screens */}
                <div className={fixed ? 'topbar_container fixed' : 'topbar_container'}>
                    <h4><i class="fa fa-list"></i> Categories</h4>
                    <ul>
                        {
                            servicesDATA.map((obj, i) => (
                                <li key={i} onClick={() => handleCategoryPress(obj.category)} className={active == obj.category ? 'active' : ''}>{obj.category}</li>
                            ))
                        }
                    </ul>

                    <div className='searchBar' style={searchBarDisplay ? { display: 'flex' } : { display: 'none' }}>
                        <i className="fa fa-magnifying-glass" aria-hidden="true" />
                        <input type='search' placeholder='Search for service and hire an Expert' value={searchValue} onChange={(e) => searchInput(e.target.value)}></input>
                    </div>
                </div>

                {/* Services Container */}
                <div ref={servicesContainer} className={fixed ? 'services_container fixed' : 'services_container'}>
                    {
                        active && servicesDATA[servicesDATA.findIndex((obj) => { return obj.category === active })].content.map((obj, idx) => (
                            <div key={idx} className='card'>
                                <div className='discountTag'>
                                    <h5>{obj.discount}% <span>off</span></h5>
                                </div>
                                <h4>{obj.title}</h4>
                                <h2>&#8377; {obj.amount}/-
                                    {
                                        obj.basis ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                <span className='discountAmount'> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                                <span> {
                                                    obj.basis === 'monthly' ? 'per month' : obj.basis === 'quarterly' ? 'per 4 months' : 'per year'
                                                }</span>
                                            </div>
                                        ) : (
                                            <span className='discountAmount' style={{ alignSelf: 'flex-end', paddingBottom: '5px' }}> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                        )
                                    }
                                </h2>
                                <div className='buttons'>
                                    <a onClick={() => handleRequestForm(obj.title)}>Request A Callback</a>
                                </div>
                            </div>
                        ))
                    }
                    {
                        searchedDATA.length != 0 && searchedDATA.map((obj, idx) => (
                            <div key={idx} className='card'>
                                <div className='discountTag'>
                                    <h5>{obj.discount}% <span>off</span></h5>
                                </div>
                                <h4>{obj.title}</h4>
                                <h2>&#8377; {obj.amount}/-
                                    {
                                        obj.basis ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                <span className='discountAmount'> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                                <span> {
                                                    obj.basis === 'monthly' ? 'per month' : obj.basis === 'quarterly' ? 'per 4 months' : 'per year'
                                                }</span>
                                            </div>
                                        ) : (
                                            <span className='discountAmount' style={{ alignSelf: 'flex-end', paddingBottom: '5px' }}> &#8377;{calculateGrossAmount(obj.amount, obj.discount)}/-</span>
                                        )
                                    }
                                </h2>
                                <div className='buttons'>
                                    <a onClick={() => handleRequestForm(obj.title)}>Request A Callback</a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default Services_Pricing