import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './headerStyle.css'

//Import IMAGES
import LOGO from '../../Assets/images/FInEra_LOGO.png'

//Importing GSAP for Animation
import gsap from 'gsap'; // <-- import GSAP
import { useGSAP } from '@gsap/react'; // <-- import the hook from our React package

gsap.registerPlugin(useGSAP)

function Header({ states }) {
    //Refs
    const container = useRef(null)

    useGSAP(
        () => {
            // gsap code here...
            const tl = gsap.timeline()
            tl.from('.logo_container', { x: -80, opacity: 0, duration: 0.5, delay: 0.3, });
            tl.from('.navbar_container ul li', {x: 80, opacity: 0, duration: 0.5, stagger: -0.1}) // <-- automatically reverted
        },
        { scope: container }
    ); // <-- scope for selector text (optional)

    //States
    const [navbarDisplay, setnavbarDisplay] = useState(false)

    //functions
    const handleNavbarDisplay = () => {
        if (navbarDisplay) {
            setnavbarDisplay(false)
            //UnLock Scroll
            document.body.style.overflow = '';
        } else {
            setnavbarDisplay(true)
            //Lock Scroll
            document.body.style.overflow = 'hidden';
        }
    }

    return (
        <>
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light bg-transparent" id="gtco-main-nav">
                <div className="container"><a className="navbar-brand"><img src={LOGO} style={{height: '70px'}}/></a>
                    <button className="navbar-toggler" data-target="#my-nav" onclick="myFunction(this)" data-toggle="collapse"><span className="bar1" /> <span className="bar2" /> <span className="bar3" /></button>
                    <div id="my-nav" className="collapse navbar-collapse" style={{justifyContent: 'flex-end'}}>
                        <ul className="navbar-nav">
                            <li className="nav-item"><a className="nav-link" href="#">Home</a></li>
                            <li className="nav-item"><a className="nav-link" href="#services">Services</a></li>
                            <li className="nav-item"><a className="nav-link" href="#about">About</a></li>
                            <li className="nav-item"><a className="nav-link" href="#news">FAQ's</a></li>
                            <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav> */}

            <div ref={container} className="header_container">
                <div className="logo_container"><a className="" href='#'><img src={LOGO} /></a>
                    {/* <button className=""><span className="bar1" /> <span className="" /> <span className="" /></button> */}
                </div>
                <div onClick={handleNavbarDisplay} className={navbarDisplay ? 'navbar_button_container active' : 'navbar_button_container'}>
                    <span className='line1'></span>
                    <span className='line2'></span>
                    <span className='line3'></span>
                </div>
                <div className={navbarDisplay ? "sidenavbar_container active" : "sidenavbar_container"} >
                    <ul>
                        <li><Link onClick={() => { states('.newBannerContainer'); handleNavbarDisplay() }} className="" to="/">Home</Link></li>
                        <li><Link onClick={handleNavbarDisplay} className="" to="/services&pricing">Services</Link></li>
                        <li><Link onClick={() => { states('#about'); handleNavbarDisplay() }} className="" to="/">About</Link></li>
                        <li><Link onClick={() => { states('#faq'); handleNavbarDisplay() }} className="" to="/">FAQ's</Link></li>
                        <li><Link onClick={() => { states('#contact'); handleNavbarDisplay() }} className="" to="/">Contact</Link></li>
                    </ul>
                </div>
                <div className="navbar_container" >
                    <ul>
                        <li><Link onClick={() => { states('.newBannerContainer') }} className="" to="/">Home</Link></li>
                        <li><Link className="" to="/services&pricing">Services</Link></li>
                        <li><Link onClick={() => { states('#about') }} className="" to="/">About</Link></li>
                        <li><Link onClick={() => { states('#faq') }} className="" to="/">FAQ's</Link></li>
                        <li><Link onClick={() => { states('#contact') }} className="" to="/">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Header