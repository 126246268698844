//React Router DOM
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { useEffect, useRef } from 'react';

//Importing Layout
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

//Importing Pages
import Homepage from './Pages/HomePage/Homepage';
import Services_Pricing from './Pages/Services&Pricing/Services&Pricing';
import { useState } from 'react';

function App() {
  //States
  const [_id, set_id] = useState(null)

  //Functions
  const smoothScroll = (_id) => {
    if (_id !== null) {
      const elem = document.querySelector(_id)
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }

  //Locations
  const location = useLocation();

  useEffect(() => {
    const executeAfterRouteChange = () => {
      // Your function to execute after route change
      if (_id !== null) {
        setTimeout(() => {
          smoothScroll(_id);
        }, 300);
      }
      set_id(null)
    };

    executeAfterRouteChange();
  }, [location, _id]);

  return (
    <>
      <Header states={set_id} />
      <main>
        <Routes>

          <Route path='/' element={<Homepage states={set_id} />} />
          <Route path='/home' element={<Homepage />} />
          <Route path='/services&pricing' element={<Services_Pricing />} />

        </Routes>
      </main>
      <Footer states={set_id} />
    </>
  );
}

export default App;
