import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//Importing Styles
import './footerStyle.css'

//Importing Loader
import { Hourglass } from 'react-loader-spinner'

//Importing Assets
import LOGO from '../../Assets/images/FinEra_LOGO_Darkmode.png'

function Footer({ states }) {
    //States
    const [loader, setloader] = useState(false)
    const [thankYou, setthankYou] = useState(false) //Thank You Form
    const [serverResponse, setServerResponse] = useState(null)
    const [validation, setvalidation] = useState({
        name: false,
        email: false,
        message: false,
    })

    //Variables
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')

    //Functions
    const handleNameInput = (e) => {
        setname(e.target.value)
        setvalidation((prevValue) => (
            {
                ...prevValue,
                ['name']: true
            }
        ))
    }
    const handleEmailInput = (e) => {
        setemail(e.target.value)
        if (e.target.value != "") {
            if (e.target.value.includes('@')) {
                setvalidation((prev) => ({ ...prev, ['email']: true }))
            }
            else {
                setvalidation((prev) => ({ ...prev, ['email']: false }))
            }
        } else {
            setvalidation((prev) => ({ ...prev, ['email']: false }))
        }
    }
    const handleMessageInput = (e) => {
        setmessage(e.target.value)
        setvalidation((prevValue) => (
            {
                ...prevValue,
                ['message']: true
            }
        ))
    }

    const handleValidation = () => {
        let status = null
        Object.keys(validation).forEach((_id) => {
            if (validation[_id] === false) {
                status = false
            }
        })

        if (status != null && status === false) {
            return false
        } else {
            return true
        }
    }

    const handleSend = async () => {
        //Validation
        if (handleValidation() === false) {
            alert('Please Fill all the Details Properly')
            return
        } else {
            //Further Processing
            setloader(true)

            try {
                const response = await fetch('https://api.finera.net.in/api/v1/contact', {
                    method: "POST",
                    body: JSON.stringify({ name: name, email: email, message: message }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then((res) => res.json())

                if (response.success === true) {
                    setloader(false)
                    setthankYou(true)
                    setServerResponse(null)
                } else if (response.success === false && response.message === "Message not Sent") { //Don't Change the Message Condition
                    setloader(false)
                    setthankYou(true)
                    setServerResponse({ heading: response.message, message: response.content })
                } else {
                    setloader(false)
                    setthankYou(true)
                    setServerResponse({ heading: 'Message Not Sent', message: 'Internal Server Problem, sorry for your inconvenience. We are Looking into the matter.' })
                }
            } catch (error) {
                console.log(error)
                setloader(false)
                setthankYou(true)
                setServerResponse({ heading: 'Message Not Sent', message: 'Internal Server Problem, sorry for your inconvenience. We are Looking into the matter.' })
            }
        }
    }

    const handleThankYou = () => {
        if (thankYou) {
            setthankYou(!thankYou)
            setServerResponse(null)
        }
    }

    return (
        <>
            <footer className='footer_container'>
                <div className='row1'>
                    <div className='company_content'>
                        <h4>Quick Links</h4>
                        <ul>
                            <li><Link onClick={() => { states('.bannerContainer') }} to="/">Home</Link></li>
                            <li><Link to="/services&pricing">Services</Link></li>
                            <li><Link onClick={() => { states('#about') }} to="/">About</Link></li>
                            <li><Link onClick={() => { states('#faq') }} to="/">FAQ's</Link></li>
                            <li><Link onClick={() => { states('#contact') }} to="/">Contact</Link></li>
                        </ul>
                    </div>
                    <div className='services_content'>
                        <h4>Services</h4>
                        <ul>
                            <li><Link to='/services&pricing' state={{ category: 'Registrations' }}>Registrations</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'Licenses' }}>Licenses</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'TDS Preparation' }}>TDS Preparations</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'GST Filing' }}>GST Filing</Link></li>
                            <li><Link to='/services&pricing' state={{ category: 'IT Filing' }}>IT Filing</Link></li>
                            <li><Link to='/services&pricing'>All Services</Link></li>
                        </ul>
                    </div>
                    <div id='contact' className='contactUs'>
                        <h4> Contact Us </h4>
                        {
                            !thankYou ? (
                                <>
                                    <input type="text" className="form-control" placeholder="Full Name" value={name} onChange={(e) => { handleNameInput(e) }} required />
                                    <input type="email" className="form-control" placeholder="Email Address" value={email} onChange={(e) => { handleEmailInput(e) }} required />
                                    <textarea className="form-control" placeholder="Message" value={message} onChange={(e) => { handleMessageInput(e) }} required />
                                    {
                                        !loader ? (
                                            <a onClick={(e) => { e.preventDefault(); handleSend() }} href="" className="submit-button">
                                                SEND < i className="fa fa-angle-right" aria-hidden="true" />
                                            </a>
                                        ) : (
                                            <div className="loader">{
                                                <Hourglass
                                                    visible={true}
                                                    height="25"
                                                    width="25"
                                                    ariaLabel="hourglass-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    colors={['white', 'white']}
                                                />}</div>
                                        )
                                    }
                                </>
                            ) : (
                                <div className='thankyouContainer'>
                                    <div className='heading'>
                                        {
                                            serverResponse == null ? (
                                                <>
                                                    <h2>Thank you for contacting us!</h2>
                                                    <p>We have received your message.<br />Our team will reach you within less than 24 hours.</p>
                                                </>
                                            ) : (
                                                <>
                                                    <h2>{serverResponse.heading}</h2>
                                                    <p>{serverResponse.message}</p>
                                                </>
                                            )
                                        }
                                    </div>

                                    <div className='footing'>
                                        <button onClick={handleThankYou}>OK</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className='row2'>
                    <div className='logo_container'>
                        <img src={LOGO}></img>
                    </div>
                    <div className='copyright'>
                        <p>© 2024. All Rights Reserved.</p>
                    </div>
                    <div className='content'>
                        <h4 className="">Follow Us</h4>
                        <ul className="">
                            <li className=""><a className="" href="#"><i className="fa fa-facebook" aria-hidden="true" /></a></li>
                            <li className=""><a className="" href="#"><i className="fa fa-twitter" aria-hidden="true" /></a></li>
                            <li className=""><a className="" href="#"><i className="fa fa-google" aria-hidden="true" /></a></li>
                            <li className=""><a className="" href="#"><i className="fa fa-linkedin" aria-hidden="true" /></a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer